var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['relative overflow-hidden lg:h-160', _vm.backgroundColor]},[_c('svg',{staticClass:"hidden lg:block absolute left-[-180px] top-[155px] lg:left-[-100px] lg:-top-[75px]",attrs:{"width":"550","height":"550","viewBox":"0 0 550 550","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"#ffffff","fill-opacity":"0.3","fill-rule":"evenodd","stroke":"none","d":"M 277.591187 0 L 550 275 L 277.591187 550 C 238.773193 513.252991 238.773193 453.663971 277.591187 416.900024 L 418.154083 275 L 277.591187 133.100037 C 238.773193 96.335907 238.773193 36.764099 277.591187 0 Z M 29.114031 0 L 301.522186 275 L 29.114031 550 C -9.704649 513.252991 -9.704649 453.663971 29.114031 416.900024 L 169.676743 275 L 29.114031 133.100037 C -9.704649 96.335907 -9.704649 36.764099 29.114031 0 Z"}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-col h-full max-w-[1320px] m-auto"},[_c('div',{class:[
        'order-2 flex items-center py-20 px-8 lg:w-full min-h-[240px] lg:h-full',
        {
          'lg:max-w-[60%]': !_vm.width,
          'lg:max-w-[100%]': _vm.width === 'full',
        },
      ]},[_c('svg',{staticClass:"lg:hidden absolute left-[-230px] h-full lg:left-[-100px]",attrs:{"viewBox":"0 0 550 550","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"#ffffff","fill-opacity":"0.3","fill-rule":"evenodd","stroke":"none","d":"M 277.591187 0 L 550 275 L 277.591187 550 C 238.773193 513.252991 238.773193 453.663971 277.591187 416.900024 L 418.154083 275 L 277.591187 133.100037 C 238.773193 96.335907 238.773193 36.764099 277.591187 0 Z M 29.114031 0 L 301.522186 275 L 29.114031 550 C -9.704649 513.252991 -9.704649 453.663971 29.114031 416.900024 L 169.676743 275 L 29.114031 133.100037 C -9.704649 96.335907 -9.704649 36.764099 29.114031 0 Z"}})]),_vm._v(" "),_c('div',{staticClass:"relative flex flex-col gap-6 w-full text-white"},[_c('h1',{staticClass:"headline"},[_vm._t("title")],2),_vm._v(" "),(_vm.$slots.description)?_c('div',{staticClass:"text-xl"},[_vm._t("description")],2):_vm._e(),_vm._v(" "),(_vm.$slots.body)?_c('div',{staticClass:"w-full z-20"},[_vm._t("body")],2):_vm._e(),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"w-full z-10"},[_vm._t("footer")],2):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"order-1 relative lg:absolute h-[220px] lg:top-0 lg:right-0 lg:w-[35%] lg:h-full"},[_vm._t("image",function(){return [_c('NuxtImg',{staticClass:"w-full h-full object-cover",attrs:{"src":_vm.image}})]}),_vm._v(" "),(_vm.gradient)?_c('div',{staticClass:"absolute top-0 left-0 h-full w-full bg-gradient-to-r from-black/75"}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }